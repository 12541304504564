<template>
<div class="recruite">
    <a-layout>
        <a-layout-content v-if="_mobile" style="overflow-x:hidden;">
            <a-row class="m-row" style="background-color:white;padding-top:47px;">
                <a-col :span="24">
                    <div style="display:flex;margin-bottom:50px">
                        <img style="width:114px;height:20px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Flogo%402x.png?alt=media&token=bfc7185b-4e3c-4cd4-80ea-31e93ed58d11" />
                        <img style="wdith:147px;height:40px;margin-top:-10px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-recruit%402x.png?alt=media&token=d89e6501-5d7a-4147-9089-587f1154d8a5" />

                    </div>
                    <a-row>
                        <a-col :span="24" v-if="isLoad" style="z-index:20">
                            <div class="mtitle1">더 좋은 제품을<br />소비자에게</div>
                        </a-col>
                        <a-col :span="24">
                            <div><img @load="loaded" style="width:100%;margin-top:-30px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-main%402x.png?alt=media&token=04b5afc9-20bd-40b1-b92c-763cc23f6aa2" /></div>
                        </a-col>
                        <a-col :span="24" style="margin-top:30px" v-if="isLoad">
                            <div class="s-re-font1-m">
                                스토어링크는 마케팅에 어려움을 겪는<br />온라인 판매자를 위해 시작된 플랫폼 서비스입니다.
                            </div>
                            <!-- <div v-else>
                                <a-skeleton />
                            </div> -->
                        </a-col>
                        <a-col :span="24" style="margin-top:20px" v-if="isLoad">
                            <div class="s-re-font2-m">
                                좋은 제품을 알리지 못해 고민인 판매자들.<br />
                                이런 좋은 제품을 왜 알지 못했을까 생각하는 저를 비롯한 소비자들.<br />
                                스토어링크는 판매자가 오롯이 제품에만 집중할 수 있도록 만들어주고, 더 좋은 제품을 소비자에게 전달할 수 있도록 하는 매개체입니다.<br />
                                온라인 판매자를 위한 최고의 마케팅 플랫폼을 함께 만들어 갈 분들을 기다리고 있습니다.
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row class="m-row" style="background-color:#f8f8f8;" v-if="isLoad">
                <a-col :span="24">
                    <div class="s-re-font3-m" style="margin-bottom:20px">진행중인 채용정보</div>
                    <div>
                        <a-row>
                            <a-col :span="12">
                                <a href="https://www.rocketpunch.com/jobs/86520/%ED%92%80%EC%8A%A4%ED%83%9D-%EA%B0%9C%EB%B0%9C%EC%9E%90" target="_blank">
                                    <div class="s-re-btn1-m">
                                        풀스택 개발자
                                    </div>
                                </a>
                            </a-col>
                            <a-col :span="12">
                                <a href="https://www.rocketpunch.com/jobs/86610/Product-OwnerPO" target="_blank">
                                    <div class="s-re-btn1-m">기획자</div>
                                </a>
                            </a-col>
                            <a-col :span="12">
                                <a href="https://www.rocketpunch.com/jobs/86229/%EB%94%94%EC%9E%90%EC%9D%B4%EB%84%88" target="_blank">
                                    <div class="s-re-btn1-m">디자이너</div>
                                </a>
                            </a-col>
                            <a-col :span="12">
                                <a href="https://www.rocketpunch.com/jobs/85737/%EC%BB%A4%EB%A8%B8%EC%8A%A4-MD" target="_blank">
                                    <div class="s-re-btn1-m">커머스 MD(종합)</div>
                                </a>
                            </a-col>
                            <a-col :span="12">
                                <a href="https://www.rocketpunch.com/jobs/86228/%EC%BB%A4%EB%A8%B8%EC%8A%A4-MD%EA%B1%B4%EA%B0%95%EA%B8%B0%EB%8A%A5%EC%8B%9D%ED%92%88%EA%B1%B4%EA%B0%95%EC%8B%9D%ED%92%88" target="_blank">
                                    <div class="s-re-btn1-m">커머스 MD(건기식)</div>
                                </a>
                            </a-col>
                            <a-col :span="12">
                                <a href="https://www.rocketpunch.com/jobs/81427/%EC%98%81%EC%97%85" target="_blank">
                                    <div class="s-re-btn1-m">영업(B2B)</div>
                                </a>
                            </a-col>
                        </a-row>
                    </div>
                    <div class="s-re-font3-m" style="margin-top:50px;margin-bottom:20px">마감된 채용정보</div>
                    <div>
                        <a-row>
                            <a-col :span="12">
                                <div class="s-re-btn2-m">온라인 마케터</div>
                            </a-col>
                            <a-col :span="12">
                                <div class="s-re-btn2-m">운영</div>
                            </a-col>
                            <a-col :span="12">
                                <div class="s-re-btn2-m">제품기획</div>
                            </a-col>
                            <a-col :span="12">
                                <div class="s-re-btn2-m">데이터 개발자</div>
                            </a-col>
                        </a-row>
                    </div>
                </a-col>
            </a-row>
            <a-row class="m-row"  style="background-color:#0264fb;" v-if="isLoad">
                <a-col :span="24">
                    <div class="s-re-font4-m">회사 소개</div>
                    <div class="s-re-font5-m">안녕하세요. 스토어링크 대표 정용은입니다.<br />
                        저는 2년간 Hyundai Card, CJ, Expedia 등 국내외 300개 기업의 인플루언서 마케팅을 대행하다 좀 더 본질적인 마케팅에 대한 갈증을 느껴 각종 마케팅 채널과 그 효율에 대해 분석했습니다.<br />
                        제품에 따라 적합한 마케팅 채널과 방법은 전혀 달랐고, 특정 카테고리에서는 제품비의 90% 이상이 마케팅비로 구성되어 있었습니다.<br />
                        제품비의 구성이 비이성적이고 불합리하다고 생각했고, 이를 해결하기 위해 노력했습니다.<br />
                        그렇게 탄생한 서비스가 스토어링크입니다.<br />
                        스토어링크는 제품에 따라 어떤 마케팅을 해야 하는지 ROAS를 기반으로 진단해 드리고, 네이버 쇼핑, 쿠팡 등 쇼핑몰의 알고리즘을 분석해 합리적인 마케팅 비용으로 제품을 관리할 수 있도록 만들었습니다.<br />
                        실제로 좋은 제품을 만들었지만 마케팅을 몰라 성공하지 못했던 기업들이 스토어링크를 사용하며 성장에 성장을 거듭하는 모습을 보며 보람을 느꼈습니다.<br />
                        입소문을 통해 1년만에 3천곳이 넘는 판매자 분들이 스토어링크를 선택해 주셨고, 1만개가 넘는 제품을 맡겨주셨습니다. <br />이를 통해 스토어링크도 월 평균 15%의 성장을 거듭할 수 있었고, 20억원의 투자금을 유치할 수 있었습니다.<br />
                        그리고 이제는 입소문을 넘어 적극적인 마케팅을 통해 좋은 제품을 가진 판매자 분들을 발굴하고, 스토어링크도 제품 기획부터 참여하는 형태로 확장해 결국에는 더 좋은 제품을 소비자에게 잘 전달할 수 있는 회사가 되고 싶습니다.</div>
                </a-col>
            </a-row>
            <a-row class="m-row" style="background-color:white;" v-if="isLoad">
                <a-col :span="24">
                    <div class="s-re-font3" style="margin-bottom:20px;text-align:center">서비스 소개</div>
                    <a-row>
                        <a-col :span="24">
                            <div >
                                <div>
                                    <img style="width:100%" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-service-1%402x.png?alt=media&token=4ff5be60-e0e1-4c6d-bd51-45e99bd5d669" />
                                </div>
                                <div class="s-re-font6-m">
                                    온라인 판매자를 위한 퍼포먼스 마케팅 플랫폼 ‘스토어링크’
                                </div>
                                <div class="s-re-font7-m">
                                    스토어링크는 네이버 쇼핑, 쿠팡 등 각 쇼핑몰에서 제품을 판매하고 있는 판매자들이 자신의 제품을 편리하게 관리할 수 있도록 도와줍니다.<br />각 쇼핑몰에서 내 제품의 순위를 알려주고, 쇼핑몰별 상위노출 알고리즘을 분석해 가장 합리적인 마케팅 비용으로 상위노출 시킵니다.<br />제품별 ROAS를 기반으로 가장 적합한 마케팅 채널을 추천하고, 해당 채널에서 즉시 마케팅을 실행할 수 있도록 진화하고 있습니다.
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="24" style="margin-top:20px">
                            <div >
                                <div>
                                    <img style="width:100%" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-service-2%402x.png?alt=media&token=716ecd0f-a698-420d-bebf-4412a27ed762" />
                                </div>
                                <div class="s-re-font6-m">
                                    구매 체험단 쇼핑몰 ‘퍼그샵’
                                </div>
                                <div class="s-re-font7-m">
                                    퍼그샵은 다양한 제품을 일반인 누구나 무료로 받을 수 있는 쇼핑몰이면서 체험단 사이트입니다.<br />자세한 설명은 아래 홍보영상과 각종 소개글로 대체합니다.
                                </div>
                                <div style="display:flex;margin-top:20px">
                                    <div class="s-re-btn3">
                                        <a href="https://youtu.be/rNYhvzBqATw" target="_blank">홍보영상</a>
                                    </div>
                                    <div class="s-re-btn3" style="margin-left:10px">
                                        <a href="https://pugshop.co.kr/guide/about-pugshop" target="_blank">퍼그샵 소개글</a>
                                    </div>
                                </div>
                            </div>
                        </a-col>

                    </a-row>
                </a-col>
            </a-row>

            <a-row class="m-row" style="background-color:#f8f8f8;" v-if="isLoad">
                <a-col :span="24">
                    <div class="s-re-font3-m" style="margin-bottom:0px;text-align:center">성과 지표</div>
                    <a-row>
                        <a-col :span="24">
                            <div>
                                <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-outcome-m%402x.png?alt=media&token=4ed00e18-f16a-4d7f-b5c8-a2de37ad369f" style="width:100%" />
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row class="m-row" style="background-color:white;margin-bottom:0px" v-if="isLoad">
                <a-col :span="24">
                    <div class="s-re-font3-m" style="margin-bottom:20px;">조직 문화</div>
                    <a-row>
                        <a-col :span="24">
                            <div class="s-re-font8-m">
                                우리는 주도적으로 성장하면서 함께하기 위해 노력합니다.<br />
                                주도적이기 위해 자유와 책임을, 성장하기 위해 내적 동기를, 함께하기 위해 최소한의 매뉴얼을 드립니다.<br />
                                주어진 일만 하기보다 스스로 일을 만들어 할 수 있는 분을 좋아합니다. <br />
                                그 일이 개인과 회사의 성장에 도움이 될 수 있도록 회사는 노력합니다.<br />
                                느린 것보다는 멈추는 것을 두려워하는 분을 좋아합니다. 실패를 두려워하지 않을 수 있도록 회사는 지원합니다.
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row class="m-row" style="margin-top:0px;background-color:white;padding-top:0px" v-if="isLoad">
                <a-col :span="24">
                    <div class="s-re-font3-m" style="margin-bottom:20px;">조직 구성</div>
                    <a-row>
                        <a-col :span="24">
                            <div class="s-re-font8-m">
                                <a href="https://www.rocketpunch.com/companies/storelink/jobs" target="_blank"><img style="width:100%;cursor:pointer" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-organizationchart-m2x.png?alt=media&token=c77c3b5d-e544-4443-bc3c-e9cda4ee2de1" /></a>
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row class="m-row" style="background-color:#f8f8f8;" v-if="isLoad">
                <a-col :span="24">
                    <div class="s-re-font3-m" style="margin-bottom:20px;">복지</div>
                    <a-row>
                        <a-col :span="24">
                            <div class="s-re-font8-m">
                                다른 곳보다 특별하지 않지만 특별해지기 위해 노력합니다.
                            </div>
                        </a-col>
                    </a-row>
                    <a-row style="margin-top:20px">
                        <a-col :span="12">
                            <div class="s-re-rec1-m right">
                                주 35시간
                            </div>
                        </a-col>
                        <a-col :span="12">
                            <div class="s-re-rec1-m left">
                                탄력근무제
                            </div>
                        </a-col>
                        <a-col :span="12">
                            <div class="s-re-rec1-m right">
                                재택근무제
                            </div>
                        </a-col>
                        <a-col :span="12">
                            <div class="s-re-rec1-m left">
                                월 1회 4.5일제
                            </div>
                        </a-col>
                        <a-col :span="12">
                            <div class="s-re-rec1-m right">
                                3년 근속 안식월
                            </div>
                        </a-col>
                        <a-col :span="12">
                            <div class="s-re-rec1-m left">
                                점심, 간식, 와인 제공
                            </div>
                        </a-col>

                    </a-row>
                </a-col>
            </a-row>
            <a-row class="m-row" style="background-color:white;" v-if="isLoad">
                <a-col :span="24">
                    <div class="s-re-font3-m" style="margin-bottom:20px;text-align:center">일터</div>
                    <a-row>
                        <a-col :span="12">
                                <div>
                                    <img style="width:100%;border-radius:5px;margin-right:5px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-workspace-1%402x.png?alt=media&token=cd0cc8dc-2047-4ca3-a026-9baf2a7b78a5" />
                                </div>
                        </a-col>
                        <a-col :span="12">
                                <div>
                                    <img style="width:100%;border-radius:5px;margin-left:5px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-workspace-2%402x.png?alt=media&token=14e05f5d-6de1-4144-a396-3f08fcfc97d6" />
                                </div>
                        </a-col>
                        <a-col :span="24">
                            <div class="s-re-font6-m">
                                서울역 언저리에 위치한 사무실
                            </div>
                            <div class="s-re-font7-m">
                                스토어링크는 서울역 서부역쪽 만리동에 위치해 있습니다.<br />찻길에서 사람길로 바뀐 서울로와 연결된 만리동은 작고 예쁜 가게 많은 공간이고,<br />이곳에 있는 스카이천사빌딩 9F(2021년 1월 입주예정), B1, B2 총 3개 층을 사용하고 있습니다.
                            </div>

                        </a-col>
                    </a-row>
                    <a-row style="margin-top:60px">
                        <a-col :span="12">
                                <div>
                                    <img style="width:100%;border-radius:5px;margin-right:5px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-workspace-3%402x.png?alt=media&token=0d717a24-cb7e-4d03-a3ff-d77f949a55fb" />
                                </div>
                        </a-col>
                        <a-col :span="12">
                                <div>
                                    <img style="width:100%;width:100%;border-radius:5px;margin-left:5px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-workspace-4%402x.png?alt=media&token=2ec5b751-bb7e-4e51-9328-0887bd689569" />
                                </div>
                        </a-col>
                        <a-col :span="24">
                            <div class="s-re-font6-m">
                            </div>
                            <div class="s-re-font7-m">
                                B2는 직원들의 휴게공간으로 사용하고 있으며, 빈백이나 안마의자에서 편하게 휴식을 취할 수 있습니다.<br />Pub 느낌으로 조명을 설정할 수 있으며, 음료와 와인냉장고가 별도로 비치되어 있어 임직원은 자신의 지인들을 초대해 시크릿하게 이용할 수 있습니다.
                            </div>

                        </a-col>
                    </a-row>
                </a-col>
            </a-row>

            <a-row class="m-row" style="background-color:#f8f8f8;" v-if="isLoad">
                <a-col :span="24">
                    <div class="s-re-font3-m" style="margin-bottom:20px;">채용 프로세스</div>
                    <a-row>
                        <a-col :span="24">
                            <div class="s-re-font8-m">
                                서류 접수는 웹사이트에 등록되어 있는 채용공고를 클릭해 로켓펀치로 이동하셔서 접수하시거나 이메일(gini@storelink.io)로 접수해주세요. <br />이메일로 접수하시는 분들은 아래 사전 질문지를 확인하셔서 함께 제출해주시길 부탁드립니다. <br />서류 접수 후 피드백과 인터뷰의 피드백은 영업일 기준 3일 이내에 보내드리겠습니다.
                            </div>
                        </a-col>
                        <a-col :span="24">
                            <div class="s-re-btn4-m">
                                <a href="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2F(20.12.24)%E1%84%89%E1%85%B3%E1%84%90%E1%85%A9%E1%84%8B%E1%85%A5%E1%84%85%E1%85%B5%E1%86%BC%E1%84%8F%E1%85%B3%20%E1%84%8B%E1%85%B5%E1%86%B8%E1%84%89%E1%85%A1%20%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%B5%20%E1%84%89%E1%85%A1%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%8C%E1%85%B5%E1%86%AF%E1%84%86%E1%85%AE%E1%86%AB(%E1%84%92%E1%85%A9%E1%86%BC%E1%84%80%E1%85%B5%E1%86%AF%E1%84%83%E1%85%A9%E1%86%BC).docx?alt=media&token=8b73f477-649c-44d4-a2db-f303441a98b4" download><i class="fal fa-arrow-to-bottom"></i><span style="padding-left:5px">사전질문 다운받기</span></a>
                            </div>
                        </a-col>
                        <a-col :span="24" style="margin-top:30px">
                            <div>
                                <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-organizationchart-m2x.png?alt=media&token=c77c3b5d-e544-4443-bc3c-e9cda4ee2de1" style="width:100%">
                            </div>
                        </a-col>
                        <a-col :span="24" style="margin-top:30px">
                            <div class="s-re-font9-m" style="margin-bottom:0px">
                                사전 질문
                            </div>
                            <div class="s-re-font10">
                                1. 스토어링크에 지원하신 이유를 알고 싶습니다.
                            </div>
                            <div class="s-re-font10">
                                2. 주도적으로 일을 하시는 분인가요? 왜 그렇게 생각하시나요?
                            </div>
                            <div class="s-re-font10">
                                3. 지원하시는 직군에서 일을 하기 위해 경험했거나, 공부했거나, 노력했던 일들에 대해 알려주세요.
                            </div>
                            <div class="s-re-font10">
                                4. 지원하시는 직군에서 이루고 싶은 것들에 대해 알려주세요.
                            </div>
                        </a-col>
                    </a-row>

                </a-col>
            </a-row>
            <a-row class="m-row" style="background-color:white;" v-if="isLoad">
                <a-col :span="24">
                    <div class="s-re-font3-m" style="margin-bottom:20px;">언론 보도</div>
                    <a-row>
                        <a-col :span="24">
                            <a href="https://platum.kr/archives/154668" target="_blank">
                                <div class="s-re-rec2-m">
                                    <div class="s-re-font11">퍼포먼스 마케팅 플랫폼<br />‘스토어링크’, 20억 원 투자유치</div>
                                    <div class="s-re-font12">2020.12.16</div>
                                </div>
                            </a>
                        </a-col>
                        <a-col :span="24">
                            <a href="https://www.hankyung.com/economy/article/202004248231a" target="_blank">
                                <div class="s-re-rec2-m">
                                    <div class="s-re-font11">㈜스토어링크, 온라인 판매자<br />위한 퍼포먼스 마케팅 플랫폼으로 주목</div>
                                    <div class="s-re-font12">2020.04.24</div>
                                </div>
                            </a>
                        </a-col>
                        <a-col :span="24">
                            <a href="http://www.sisa-news.com/news/article.html?no=124824" target="_blank">
                                <div class="s-re-rec2-m">
                                    <div class="s-re-font11">스토어링크, 온라인 판매자 <br />위한 맞춤형 마케팅 제공</div>
                                    <div class="s-re-font12">2020.04.24</div>
                                </div>
                            </a>
                        </a-col>
                        <a-col :span="24">
                            <a href="https://post.naver.com/viewer/postView.nhn?volumeNo=30343557&memberNo=11466887" target="_blank">
                                <div class="s-re-rec2-m">
                                    <div class="s-re-font11">꾸준한 온라인 마켓의 성장 속 '스토어링크',<br/> 똑똑하고 안전한 온라인 마케팅 서비스로 주목</div>
                                    <div class="s-re-font12">2020.12.29</div>
                                </div>
                            </a>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row class="m-row" style="background-color:#031125;" v-show="isLoad">
                <a-col :span="24">
                    <div class="s-re-font13-m">더 좋은 제품을 <br/>소비자에게 전달하기 위해<br />함께 하실 분들을<br/> 애타게 기다리고 있습니다.</div>
                    <div class="s-re-font14-m">
                        채용공고 소문내기
                    </div>
                    <div class="s-re-rec3-m" @click="shareKaKao()" id="kakao-link-btn">
                        <a>
                            <div style="display:flex" >
                                <div><img style="width:40px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Ficon-instagram%402x.png?alt=media&token=c4d23016-80ea-41a9-9ebc-5b1b44df0919" /></div>
                                <div  class="s-re-font16-m" style="padding-top:5px;padding-left:10px">카카오톡 공유하기</div>
                            </div>
                        </a>
                    </div>
                    <div class="s-re-font15-m">
                        Copyright © 2021, Powered by storelink.
                    </div>

                </a-col>
            </a-row>

        </a-layout-content>
        <a-layout-content v-else>
            <a-row style="padding-top:47px;padding-bottom:80px;">
                <a-col :span="16" :offset="4">
                    <div style="display:flex;margin-bottom:80px">
                        <img style="width:114px;height:20px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Flogo%402x.png?alt=media&token=bfc7185b-4e3c-4cd4-80ea-31e93ed58d11" />
                        <img style="wdith:147px;height:40px;margin-top:-10px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-recruit%402x.png?alt=media&token=d89e6501-5d7a-4147-9089-587f1154d8a5" />

                    </div>
                    <a-row>
                        <a-col :span="12" v-if="isLoad">
                            <div class="title1">더 좋은 제품을<br />소비자에게</div>
                        </a-col>
                        <a-col :span="12">
                            <div><img @load="loaded" style="width:100%;max-width:621px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-main%402x.png?alt=media&token=04b5afc9-20bd-40b1-b92c-763cc23f6aa2" /></div>
                        </a-col>
                        <a-col :span="24" style="margin-top:100px" v-if="isLoad">
                            <div class="s-re-font1">
                                스토어링크는 마케팅에 어려움을 겪는<br />온라인 판매자를 위해 시작된 플랫폼 서비스입니다.
                            </div>
                            <!-- <div v-else>
                                <a-skeleton />
                            </div> -->
                        </a-col>
                        <a-col :span="24" style="margin-top:30px;" v-if="isLoad">
                            <div class="s-re-font2">
                                좋은 제품을 알리지 못해 고민인 판매자들.<br />
                                이런 좋은 제품을 왜 알지 못했을까 생각하는 저를 비롯한 소비자들.<br />
                                스토어링크는 판매자가 오롯이 제품에만 집중할 수 있도록 만들어주고, 더 좋은 제품을 소비자에게 전달할 수 있도록 하는 매개체입니다.<br />
                                온라인 판매자를 위한 최고의 마케팅 플랫폼을 함께 만들어 갈 분들을 기다리고 있습니다.
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row style="background-color:#f8f8f8;padding-top:80px;padding-bottom:80px" v-if="isLoad">
                <a-col :span="16" :offset="4">
                    <div class="s-re-font3" style="margin-bottom:30px">진행중인 채용정보</div>
                    <div>
                        <a-row>
                            <a-col :span="6">
                                <a href="https://www.rocketpunch.com/jobs/86610/Product-OwnerPO" target="_blank">
                                    <div class="s-re-btn1">기획자</div>
                                </a>
                            </a-col>
                            <a-col :span="6">
                                <a href="https://www.rocketpunch.com/jobs/88262/%EC%82%AC%EC%9D%B4%ED%8A%B8-%EC%9A%B4%EC%98%81-CS%EA%B4%80%EB%A6%AC" target="_blank">
                                    <div class="s-re-btn1">운영/CS관리</div>
                                </a>
                            </a-col>
                            <a-col :span="6">
                                <a href="https://www.rocketpunch.com/jobs/85737/%EC%BB%A4%EB%A8%B8%EC%8A%A4-MD" target="_blank">
                                    <div class="s-re-btn1">커머스 MD(종합)</div>
                                </a>
                            </a-col>
                            <a-col :span="6">
                                <a href="https://www.rocketpunch.com/jobs/86228/%EC%BB%A4%EB%A8%B8%EC%8A%A4-MD%EA%B1%B4%EA%B0%95%EA%B8%B0%EB%8A%A5%EC%8B%9D%ED%92%88%EA%B1%B4%EA%B0%95%EC%8B%9D%ED%92%88" target="_blank">
                                    <div class="s-re-btn1">커머스 MD(건기식)</div>
                                </a>
                            </a-col>
                        </a-row>
                    </div>
                    <div class="s-re-font3" style="margin-top:60px;margin-bottom:30px">마감된 채용정보</div>
                    <div>
                        <a-row>
                            <a-col :span="6">
                                <div class="s-re-btn2">온라인 마케터</div>
                            </a-col>
                            <a-col :span="6">
                                <div class="s-re-btn2">운영</div>
                            </a-col>
                            <a-col :span="6">
                                <div class="s-re-btn2">제품기획</div>
                            </a-col>
                            <a-col :span="6">
                                <div class="s-re-btn2">데이터 개발자</div>
                            </a-col>
                             <a-col :span="6">
                                <div class="s-re-btn2">풀스택 개발자</div>
                            </a-col>
                             <a-col :span="6">
                                <div class="s-re-btn2">영업(B2B)</div>
                            </a-col>
                        </a-row>
                    </div>
                </a-col>
            </a-row>
            <a-row style="background-color:#0264fb;padding-top:80px;padding-bottom:80px" v-if="isLoad">
                <a-col :span="16" :offset="4">
                    <div class="s-re-font4">회사 소개</div>
                    <div class="s-re-font5">안녕하세요. 스토어링크 대표 정용은입니다.<br />
                        저는 2년간 Hyundai Card, CJ, Expedia 등 국내외 300개 기업의 인플루언서 마케팅을 대행하다 좀 더 본질적인 마케팅에 대한 갈증을 느껴 각종 마케팅 채널과 그 효율에 대해 분석했습니다.<br />
                        제품에 따라 적합한 마케팅 채널과 방법은 전혀 달랐고, 특정 카테고리에서는 제품비의 90% 이상이 마케팅비로 구성되어 있었습니다.<br />
                        제품비의 구성이 비이성적이고 불합리하다고 생각했고, 이를 해결하기 위해 노력했습니다.<br />
                        그렇게 탄생한 서비스가 스토어링크입니다.<br />
                        스토어링크는 제품에 따라 어떤 마케팅을 해야 하는지 ROAS를 기반으로 진단해 드리고, 네이버 쇼핑, 쿠팡 등 쇼핑몰의 알고리즘을 분석해 합리적인 마케팅 비용으로 제품을 관리할 수 있도록 만들었습니다.<br />
                        실제로 좋은 제품을 만들었지만 마케팅을 몰라 성공하지 못했던 기업들이 스토어링크를 사용하며 성장에 성장을 거듭하는 모습을 보며 보람을 느꼈습니다.<br />
                        입소문을 통해 1년만에 3천곳이 넘는 판매자 분들이 스토어링크를 선택해 주셨고, 1만개가 넘는 제품을 맡겨주셨습니다. <br />이를 통해 스토어링크도 월 평균 15%의 성장을 거듭할 수 있었고, 20억원의 투자금을 유치할 수 있었습니다.<br />
                        그리고 이제는 입소문을 넘어 적극적인 마케팅을 통해 좋은 제품을 가진 판매자 분들을 발굴하고, 스토어링크도 제품 기획부터 참여하는 형태로 확장해 결국에는 더 좋은 제품을 소비자에게 잘 전달할 수 있는 회사가 되고 싶습니다.</div>
                </a-col>
            </a-row>
            <a-row style="background-color:white;padding-top:80px;padding-bottom:80px" v-if="isLoad">
                <a-col :span="16" :offset="4">
                    <div class="s-re-font3" style="margin-bottom:30px;text-align:center">서비스 소개</div>
                    <a-row>
                        <a-col :span="12">
                            <div style="margin-right:25px">
                                <div>
                                    <img style="width:100%" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-service-1%402x.png?alt=media&token=4ff5be60-e0e1-4c6d-bd51-45e99bd5d669" />
                                </div>
                                <div class="s-re-font6">
                                    온라인 판매자를 위한 퍼포먼스 마케팅 플랫폼 ‘스토어링크’
                                </div>
                                <div class="s-re-font7">
                                    스토어링크는 네이버 쇼핑, 쿠팡 등 각 쇼핑몰에서 제품을 판매하고 있는 판매자들이 자신의 제품을 편리하게 관리할 수 있도록 도와줍니다.<br />각 쇼핑몰에서 내 제품의 순위를 알려주고, 쇼핑몰별 상위노출 알고리즘을 분석해 가장 합리적인 마케팅 비용으로 상위노출 시킵니다.<br />제품별 ROAS를 기반으로 가장 적합한 마케팅 채널을 추천하고, 해당 채널에서 즉시 마케팅을 실행할 수 있도록 진화하고 있습니다.
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="12">
                            <div style="margin-left:25px">
                                <div>
                                    <img style="width:100%" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-service-2%402x.png?alt=media&token=716ecd0f-a698-420d-bebf-4412a27ed762" />
                                </div>
                                <div class="s-re-font6">
                                    구매 체험단 쇼핑몰 ‘퍼그샵’
                                </div>
                                <div class="s-re-font7">
                                    퍼그샵은 다양한 제품을 일반인 누구나 무료로 받을 수 있는 쇼핑몰이면서 체험단 사이트입니다.<br />자세한 설명은 아래 홍보영상과 각종 소개글로 대체합니다.
                                </div>
                                <div style="display:flex;margin-top:20px">
                                    <div class="s-re-btn3">
                                        <a href="https://youtu.be/rNYhvzBqATw" target="_blank">홍보영상</a>
                                    </div>
                                    <div class="s-re-btn3" style="margin-left:10px">
                                        <a href="https://pugshop.co.kr/guide/about-pugshop" target="_blank">퍼그샵 소개글</a>
                                    </div>
                                </div>
                            </div>
                        </a-col>

                    </a-row>
                </a-col>
            </a-row>

            <a-row style="background-color:#f8f8f8;padding-top:80px;padding-bottom:80px" v-if="isLoad">
                <a-col :span="16" :offset="4">
                    <div class="s-re-font3" style="margin-bottom:10px;text-align:center">성과 지표</div>
                    <a-row>
                        <a-col :span="24">
                            <div style="margin-right:15px">
                                <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-outcome%402x.png?alt=media&token=c2efa977-717d-4ead-bdca-178f17f3faff" style="width:100%" />
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row style="background-color:white;padding-top:80px;padding-bottom:80px" v-if="isLoad">
                <a-col :span="16" :offset="4">
                    <div class="s-re-font3" style="margin-bottom:30px;">조직 문화</div>
                    <a-row>
                        <a-col :span="24">
                            <div class="s-re-font8">
                                우리는 주도적으로 성장하면서 함께하기 위해 노력합니다.<br />
                                주도적이기 위해 자유와 책임을, 성장하기 위해 내적 동기를, 함께하기 위해 최소한의 매뉴얼을 드립니다.<br />
                                주어진 일만 하기보다 스스로 일을 만들어 할 수 있는 분을 좋아합니다. <br />
                                그 일이 개인과 회사의 성장에 도움이 될 수 있도록 회사는 노력합니다.<br />
                                느린 것보다는 멈추는 것을 두려워하는 분을 좋아합니다. 실패를 두려워하지 않을 수 있도록 회사는 지원합니다.
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row style="background-color:white;padding-top:0px;padding-bottom:80px" v-if="isLoad">
                <a-col :span="16" :offset="4">
                    <div class="s-re-font3" style="margin-bottom:30px;">조직 구성</div>
                    <a-row>
                        <a-col :span="24">
                            <div class="s-re-font8">
                                <a href="https://www.rocketpunch.com/companies/storelink/jobs" target="_blank"><img style="width:100%;cursor:pointer" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-organizationchart2x.png?alt=media&token=1219d591-bc76-492d-a2cb-805683e3635e" /></a>
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row style="background-color:#f8f8f8;padding-top:80px;padding-bottom:80px" v-if="isLoad">
                <a-col :span="16" :offset="4">
                    <div class="s-re-font3" style="margin-bottom:30px;">복지</div>
                    <a-row>
                        <a-col :span="24">
                            <div class="s-re-font8">
                                다른 곳보다 특별하지 않지만 특별해지기 위해 노력합니다.
                            </div>
                        </a-col>
                    </a-row>
                    <a-row style="margin-top:30px">
                        <a-col :span="8">
                            <div class="s-re-rec1">
                                주 35시간
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="s-re-rec1 center">
                                탄력근무제
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="s-re-rec1">
                                재택근무제
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="s-re-rec1">
                                월 1회 4.5일제
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="s-re-rec1 center">
                                3년 근속 안식월
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="s-re-rec1">
                                점심, 간식, 와인 제공
                            </div>
                        </a-col>

                    </a-row>
                </a-col>
            </a-row>
            <a-row style="background-color:white;padding-top:80px;padding-bottom:80px" v-if="isLoad">
                <a-col :span="16" :offset="4">
                    <div class="s-re-font3" style="margin-bottom:30px;text-align:center">일터</div>
                    <a-row>
                        <a-col :span="12">
                            <div style="margin-right:15px">
                                <div>
                                    <img style="width:100%" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-workspace-1%402x.png?alt=media&token=cd0cc8dc-2047-4ca3-a026-9baf2a7b78a5" />
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="12">
                            <div style="margin-left:15px">
                                <div>
                                    <img style="width:100%" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-workspace-2%402x.png?alt=media&token=14e05f5d-6de1-4144-a396-3f08fcfc97d6" />
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="24">
                            <div class="s-re-font6">
                                서울역 언저리에 위치한 사무실
                            </div>
                            <div class="s-re-font7">
                                스토어링크는 서울역 서부역쪽 만리동에 위치해 있습니다.<br />찻길에서 사람길로 바뀐 서울로와 연결된 만리동은 작고 예쁜 가게 많은 공간이고,<br />이곳에 있는 스카이천사빌딩 9F(2021년 1월 입주예정), B1, B2 총 3개 층을 사용하고 있습니다.
                            </div>

                        </a-col>
                    </a-row>
                    <a-row style="margin-top:60px">
                        <a-col :span="12">
                            <div style="margin-right:15px">
                                <div>
                                    <img style="width:100%" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-workspace-3%402x.png?alt=media&token=0d717a24-cb7e-4d03-a3ff-d77f949a55fb" />
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="12">
                            <div style="margin-left:15px">
                                <div>
                                    <img style="width:100%" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-workspace-4%402x.png?alt=media&token=2ec5b751-bb7e-4e51-9328-0887bd689569" />
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="24">
                            <div class="s-re-font6">
                            </div>
                            <div class="s-re-font7">
                                B2는 직원들의 휴게공간으로 사용하고 있으며, 빈백이나 안마의자에서 편하게 휴식을 취할 수 있습니다.<br />Pub 느낌으로 조명을 설정할 수 있으며, 음료와 와인냉장고가 별도로 비치되어 있어 임직원은 자신의 지인들을 초대해 시크릿하게 이용할 수 있습니다.
                            </div>

                        </a-col>
                    </a-row>
                </a-col>
            </a-row>

            <a-row style="background-color:#f8f8f8;padding-top:80px;padding-bottom:80px" v-if="isLoad">
                <a-col :span="16" :offset="4">
                    <div class="s-re-font3" style="margin-bottom:30px;">채용 프로세스</div>
                    <a-row>
                        <a-col :span="24">
                            <div class="s-re-font8">
                                서류 접수는 웹사이트에 등록되어 있는 채용공고를 클릭해 로켓펀치로 이동하셔서 접수하시거나 이메일(gini@storelink.io)로 접수해주세요. <br />이메일로 접수하시는 분들은 아래 사전 질문지를 확인하셔서 함께 제출해주시길 부탁드립니다. <br />서류 접수 후 피드백과 인터뷰의 피드백은 영업일 기준 3일 이내에 보내드리겠습니다.
                            </div>
                        </a-col>
                        <a-col :span="24">
                            <div class="s-re-btn4">
                                <a href="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2F(20.12.24)%E1%84%89%E1%85%B3%E1%84%90%E1%85%A9%E1%84%8B%E1%85%A5%E1%84%85%E1%85%B5%E1%86%BC%E1%84%8F%E1%85%B3%20%E1%84%8B%E1%85%B5%E1%86%B8%E1%84%89%E1%85%A1%20%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%B5%20%E1%84%89%E1%85%A1%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%8C%E1%85%B5%E1%86%AF%E1%84%86%E1%85%AE%E1%86%AB(%E1%84%92%E1%85%A9%E1%86%BC%E1%84%80%E1%85%B5%E1%86%AF%E1%84%83%E1%85%A9%E1%86%BC).docx?alt=media&token=8b73f477-649c-44d4-a2db-f303441a98b4" download><i class="fal fa-arrow-to-bottom"></i><span style="padding-left:5px">사전질문 다운받기</span></a>
                            </div>
                        </a-col>
                        <a-col :span="24" style="margin-top:30px">
                            <div>
                                <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-recruitprocess%402x.png?alt=media&token=3bd03519-4b84-4034-a56f-ef94716b0919" style="width:100%">
                            </div>
                        </a-col>
                        <a-col :span="24" style="margin-top:50px">
                            <div class="s-re-font9" style="margin-bottom:10px">
                                사전 질문
                            </div>
                            <div class="s-re-font10">
                                1. 스토어링크에 지원하신 이유를 알고 싶습니다.
                            </div>
                            <div class="s-re-font10">
                                2. 주도적으로 일을 하시는 분인가요? 왜 그렇게 생각하시나요?
                            </div>
                            <div class="s-re-font10">
                                3. 지원하시는 직군에서 일을 하기 위해 경험했거나, 공부했거나, 노력했던 일들에 대해 알려주세요.
                            </div>
                            <div class="s-re-font10">
                                4. 지원하시는 직군에서 이루고 싶은 것들에 대해 알려주세요.
                            </div>
                        </a-col>
                    </a-row>

                </a-col>
            </a-row>
            <a-row style="background-color:white;padding-top:80px;padding-bottom:80px;" v-if="isLoad">
                <a-col :span="16" :offset="4">
                    <div class="s-re-font3" style="margin-bottom:30px;">언론 보도</div>
                    <a-row>
                        <a-col :span="12" >
                                <a href="https://platum.kr/archives/154668" target="_blank" >
                                    <div class="s-re-rec2 right">
                                        <div class="s-re-font11">퍼포먼스 마케팅 플랫폼<br/>‘스토어링크’,20억 원 투자유치</div>
                                        <div class="s-re-font12">2020.12.16</div>
                                    </div>
                                </a>
                        </a-col>
                        <a-col :span="12">
                                <a href="https://www.hankyung.com/economy/article/202004248231a" target="_blank"  >
                                    <div class="s-re-rec2 left">
                                        <div class="s-re-font11">㈜스토어링크, 온라인 판매자<br/>위한 퍼포먼스 마케팅 플랫폼으로 주목</div>
                                        <div class="s-re-font12">2020.04.24</div>
                                    </div>
                                </a>
                        </a-col>
                        <a-col :span="12" style="margin-top:15px">
                                <a href="http://www.sisa-news.com/news/article.html?no=124824" target="_blank" >
                                    <div class="s-re-rec2 right">
                                        <div class="s-re-font11">스토어링크, 온라인 판매자<br/>위한 맞춤형 마케팅 제공</div>
                                        <div class="s-re-font12">2020.04.24</div>
                                    </div>
                                </a>
                        </a-col>
                        <a-col :span="12"  style="margin-top:15px">
                                 <a href="https://post.naver.com/viewer/postView.nhn?volumeNo=30343557&memberNo=11466887" target="_blank" >
                                    <div class="s-re-rec2 left">
                                        <div class="s-re-font11">꾸준한 온라인 마켓의 성장 속 '스토어링크',<br/> 똑똑하고 안전한 온라인 마케팅 서비스로 주목</div>
                                        <div class="s-re-font12">2020.12.29</div>
                                    </div>
                                </a>
                        </a-col>

                    </a-row>

                </a-col>
            </a-row>
            <a-row style="background-color:#031125;padding-top:80px;padding-bottom:80px;" v-show="isLoad">
                <a-col :span="16" :offset="4">
                    <div class="s-re-font13">더 좋은 제품을 소비자에게 전달하기 위해<br />함께 하실 분들을 애타게 기다리고 있습니다.</div>
                    <div class="s-re-font14">
                        채용공고 소문내기
                    </div>
                    <div class="s-re-rec3"  @click="shareKaKao()" id="kakao-link-btn">
                        <a>
                            <div style="display:flex">
                                <div><img style="width:40px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Ficon-instagram%402x.png?alt=media&token=c4d23016-80ea-41a9-9ebc-5b1b44df0919" /></div>
                                <div style="padding-top:5px;padding-left:10px">카카오톡 공유하기</div>
                            </div>
                        </a>
                    </div>
                    <div class="s-re-font15">
                        Copyright © 2021, Powered by storelink.
                    </div>

                </a-col>
            </a-row>

        </a-layout-content>
    </a-layout>
</div>
</template>

<script>
import firebase from "firebase";
import {
  isMobile,
  showError,
  showSuccess,
  getCurrentTimeNew
} from "../../views/components/fnc.js";

export default {
  data() {
    return {
      isLoad: false
    };
  },
  computed: {
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      return _mobile;
    }
  },
  methods: {
    loaded() {
      this.isLoad = true;
      this.$vs.loading.close();
      //   console.log(this.isLoad);
    },
    shareKaKao() {
      window.Kakao.Link.createDefaultButton({
        container: "#kakao-link-btn",
        objectType: "feed",
        content: {
          title: "스토어링크 | 채용",
          description: "스토어링크 - 퍼포먼스 마케팅 플랫폼",
          imageUrl:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2FScreen%20Shot%202020-12-29%20at%206.29.27%20PM.png?alt=media&token=5e635906-7f3f-4c24-a922-9ed1f9e4cfee",
          link: {
            mobileWebUrl: "https://storelink.io/#/recruite",
            webUrl: "https://storelink.io/#/recruite",
            androidExecParams: "Storelink"
          }
        },
        social: {
          likeCount: 10,
          commentCount: 20,
          sharedCount: 341
        },
        buttons: [
          {
            title: "웹으로 이동",
            link: {
              mobileWebUrl: "https://storelink.io/#/recruite",
              webUrl: "https://storelink.io/#/recruite"
            }
          }
        ]
      });
    }
  },
  mounted() {
    this.$vs.loading();
    this.shareKaKao();
    fbq("track", "PageView");
  }
};
</script>

<style>
.recruite {
  background-color: white;
}

.recruite .title1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 4vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -3px;
  color: #000000;
  margin: 0;
  position: absolute;
  top: 70%;
  -ms-transform: translateY(70%);
  transform: translateY(70%);
}

.recruite .mtitle1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #000000;
}

.recruite .m-row {
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.s-re-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #000000;
}

.s-re-font1-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
}

.s-re-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.s-re-font2-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: -0.67px;
  color: #7d7d7d;
}

.s-re-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #000000;
}

.s-re-font3-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #000000;
}

.s-re-font4 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #ffffff;
  margin-bottom: 23px;
}

.s-re-font4-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #ffffff;
  margin-bottom: 20px;
}

.s-re-font5 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: -1px;
  color: #ffffff;
}

.s-re-font5-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: -1px;
  color: #ffffff;
}

.s-re-font6 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.75px;
  color: #0264fb;
}

.s-re-font6-m {
  margin-top: 10px;
  margin-bottom: 4px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.75px;
  color: #0264fb;
}

.s-re-font7 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.75px;
  color: #000000;
}

.s-re-font7-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.75px;
  color: #000000;
}

.s-re-font8 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.75px;
  color: #7d7d7d;
}

.s-re-font8-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.75px;
  color: #7d7d7d;
}

.s-re-font9 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.75px;
  color: #000000;
}

.s-re-font9-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
}

.s-re-font10 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.2px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
}

.s-re-font11 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  color: #000000;
}

.s-re-font12 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  color: #7d7d7d;
  padding-top: 20px;
  margin-bottom: 20px;
}

.s-re-font13 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #ffffff;
}

.s-re-font13-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #ffffff;
}

.s-re-font14 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.75px;
  color: #ffffff;
  padding-top: 30px;
  padding-bottom: 30px;
}

.s-re-font14-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: -0.67px;
  color: #ffffff;
  padding-top: 20px;
}

.s-re-font15 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.75px;
  color: #ffffff;
}

.s-re-font15-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.75px;
  color: #ffffff;
}

.s-re-font16-m {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  color: #000000;
}

.s-re-btn1 {
  height: 60px;
  border-radius: 5px;
  border: solid 1px #000000;
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  text-align: center;
  padding-top: 15px;
  cursor: pointer;
  color: #000000;
}

.s-re-btn1-m {
  height: 42px;
  border-radius: 5px;
  border: solid 1px #000000;
  background-color: #ffffff;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
  color: #000000;
}

.s-re-btn1:hover,
.s-re-btn1-m:hover {
  background-color: #000000;
  color: white;
}

.s-re-btn2 {
  height: 60px;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
  background-color: #dbdbdb;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  text-align: center;
  color: #7d7d7d;
  padding-top: 15px;
}

.s-re-btn2-m {
  height: 42px;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
  background-color: #dbdbdb;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  text-align: center;
  color: #7d7d7d;
  padding-top: 10px;
}

.s-re-btn3,
.s-re-btn3 > a {
  width: 100px;
  height: 32px;
  border-radius: 5px;
  background-color: #0264fb;
  font-size: 14px;
  padding-top: 7px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  text-align: center;
  color: #ffffff;
}

.s-re-btn4 {
  width: 255px;
  height: 60px;
  border-radius: 5px;
  background-color: #000000;
  margin-top: 30px;
  padding-top: 15px;
  text-align: center;
}

.s-re-btn4-m {
  width: 172px;
  height: 42px;
  border-radius: 5px;
  background-color: #000000;
  margin-top: 20px;
  padding-top: 10px;
  text-align: center;
}
.s-re-btn4-m > a,
.s-re-btn4-m > span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  text-align: center;
  color: #ffffff;
}

.s-re-btn4 > a,
.s-re-btn4 > span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  text-align: center;
  color: #ffffff;
}

.s-re-rec1 {
  height: 80px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: solid 1px #979797;
  background-color: #ffffff;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.75px;
  color: #7d7d7d;
  padding-top: 25px;
  padding-left: 25px;
}

.s-re-rec1.center {
  margin-left: 15px;
  margin-right: 15px;
}

.s-re-rec1-m {
  height: 42px;
  border-radius: 5px;
  border: solid 1px #979797;
  background-color: #ffffff;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  color: #7d7d7d;
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 10px;
}

.s-re-rec1-m.right {
  margin-right: 5px;
}

.s-re-rec1-m.left {
  margin-left: 5px;
}

.s-re-rec2 {
  border-radius: 5px;
  border: solid 1px #979797;
  background-color: #ffffff;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  overflow: hidden;
  height: 145px;
  width: 100%;
}

.s-re-rec2.left {
  margin-left: 15px;
}

.s-re-rec2.right {
  margin-right: 15px;
}

.s-re-rec2-m {
  border-radius: 5px;
  border: solid 1px #979797;
  background-color: #ffffff;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  margin-bottom: 10px;
}
.s-re-rec3 {
  width: 350px;
  height: 80px;
  border-radius: 5px;
  background-color: #ffffff;
  padding-left: 20px;
  padding-top: 20px;
  cursor: pointer;
  margin-bottom: 80px;
}

.s-re-rec3-m {
  width: 100%;
  height: 80px;
  border-radius: 5px;
  background-color: #ffffff;
  padding-left: 20px;
  padding-top: 20px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 50px;
}

.s-re-rec3-m > a,
.s-re-rec3 > a {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  color: #000000;
}

.recruite ::-webkit-scrollbar {
  height: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
.recruite ::-webkit-scrollbar-thumb {
  background: transparent;
}
</style>
